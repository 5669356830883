/* eslint-disable */
export default {
  'routes': [
    {
      'resolve': () => import('./../../routes/accessibility-statement/index.js'),
      'rotation': 'routes',
      'path': '/accessibility-statement/',
      'dynamic': false,
      'title': 'Accessibility Statement - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'accessibility-statement'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/cdiv/index.js'),
      'rotation': 'routes',
      'path': '/404/',
      'dynamic': false,
      'title': 'Homeschool',
      'meta': {
        'customHTMLClasses': [],
        'inactiveRedirect': true,
        'pageInfo': [
          {
            'page_info_key': '404'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/app-test/index.js'),
      'rotation': 'routes',
      'path': '/app-test/',
      'dynamic': false,
      'title': 'Homeschool',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'app-test'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/confirmation/index.js'),
      'rotation': 'routes',
      'path': '/confirmation/',
      'dynamic': false,
      'title': 'Homeschool',
      'meta': {
        'customHTMLClasses': [],
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'page_info_key': 'confirmation'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/cookie-policy/index.js'),
      'rotation': 'routes',
      'path': '/cookie-policy/',
      'dynamic': false,
      'title': 'Homeschool - Cookie Policy',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'cookie policy'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/download-app/index.js'),
      'rotation': 'routes',
      'path': '/download-app/',
      'dynamic': false,
      'title': 'Download App - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'page_info_key': 'download-app'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/email-preferences/index.js'),
      'rotation': 'routes',
      'path': '/email-preferences/',
      'dynamic': false,
      'title': 'Email Preferences - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'inactiveRedirect': true,
        'pageInfo': [
          {
            'page_info_key': 'email-preferences'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/home/index.js'),
      'rotation': 'routes',
      'path': '/',
      'dynamic': false,
      'title': 'Homeschool+',
      'meta': {
        'blockedForMembers': true,
        'customHTMLClasses': [
          'is-nmhp'
        ],
        'pageInfo': [
          {
            'page_info_key': 'home'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/ios-create-account/index.js'),
      'rotation': 'routes',
      'path': '/ios-create-account/',
      'dynamic': false,
      'title': 'Create Your Account - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': false,
        'pageInfo': [
          {
            'page_info_key': 'ios-create-account'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/login/index.js'),
      'rotation': 'routes',
      'path': '/login/',
      'dynamic': false,
      'title': 'Log In - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'page_info_key': 'login'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/logout/index.js'),
      'rotation': 'routes',
      'path': '/logout/',
      'dynamic': false,
      'title': 'Homeschool',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'logout'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/current/',
      'dynamic': false,
      'title': 'Privacy Policy - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'privacy-policy'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-previous/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/previous/',
      'dynamic': false,
      'title': 'Privacy Policy - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'privacy-policy/previous'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reactivation/index.js'),
      'rotation': 'routes',
      'path': '/reactivation/',
      'dynamic': false,
      'title': 'Sign Up - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'requiresAuthentication': false,
        'pageInfo': [
          {
            'page_info_key': 'reactivation'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeem/index.js'),
      'rotation': 'routes',
      'path': '/redeem/',
      'dynamic': false,
      'title': 'Redeem a Code - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': false,
        'pageInfo': [
          {
            'page_info_key': 'redeem'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeem-create-account/index.js'),
      'rotation': 'routes',
      'path': '/redeem/create-account/',
      'dynamic': false,
      'title': 'Create Your Account - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'page_info_key': 'redeem-create-account'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription/index.js'),
      'rotation': 'routes',
      'path': '/subscription/',
      'dynamic': false,
      'title': 'Sign Up - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'page_info_key': 'subscription'
          }
        ],
        'requiresValidRedeemCode': false
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/survey/index.js'),
      'rotation': 'routes',
      'path': '/survey/',
      'dynamic': false,
      'title': 'Homeschool',
      'meta': {
        'customHTMLClasses': [],
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'page_info_key': 'survey'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terminate/index.js'),
      'rotation': 'routes',
      'path': '/terminate/cFn0vG4yW/',
      'dynamic': false,
      'title': 'Terminate - Homeschool+',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'terminate'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terms-and-conditions/index.js'),
      'rotation': 'routes',
      'path': '/terms-and-conditions/current/',
      'dynamic': false,
      'title': 'Terms and Conditions - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'terms-and-conditions'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reset-password/index.js'),
      'rotation': 'routes',
      'path': '/reset-password/',
      'dynamic': false,
      'title': 'Reset Password - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'inactiveRedirect': true,
        'pageInfo': [
          {
            'page_info_key': 'reset-password'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terms-and-conditions-previous/index.js'),
      'rotation': 'routes',
      'path': '/terms-and-conditions/previous/',
      'dynamic': false,
      'title': 'Terms and Conditions - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'pageInfo': [
          {
            'page_info_key': 'terms-and-conditions/previous/'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade-standalone/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/sta/',
      'dynamic': false,
      'title': 'Upgrade Account - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'page_info_key': 'upgrade-standalone-page'
          }
        ]
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offers/special-offer-15-for-12/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/0a645a/',
      'dynamic': false,
      'title': 'Sign Up - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'offer': '0a645a',
            'page_info_key': 'subscription'
          }
        ],
        'requiresValidRedeemCode': false
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offers/special-offer-charter-school/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/charter-school-order-form/',
      'dynamic': false,
      'title': 'Sign Up - Homeschool+',
      'meta': {
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'offer': 'charter-school-order-form',
            'page_info_key': 'subscription'
          }
        ],
        'requiresValidRedeemCode': false
      },
      'locale': 'en',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-offers/special-offer-15-for-12-7r621b/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/7r621b/',
      'dynamic': false,
      'title': 'Sign Up - Homeschool+',
      'meta': {
        'requiresAuthentication': true,
        'customHTMLClasses': [],
        'blockedForMembers': true,
        'pageInfo': [
          {
            'offer': '7r621b',
            'page_info_key': 'subscription'
          }
        ],
        'requiresValidRedeemCode': false
      },
      'locale': 'en',
      'template': 'main'
    }
  ]
};