import {PathUtils} from '@aofl/router/modules/path-utils';
import {routerInstance} from '@aofl/router';
import {storeInstance} from '@aofl/store';
import {ApiService} from './api-service';
import {CampaignService} from './campaign-service';
import {pages, cookies, environment, eventNames, pixelEvents, queryParamKeys, localStorageKeys} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue.js';
import {ModalService} from '../modules/modal-service';
import {resolveCondition} from './page-load-utils';
import {PixelService} from './pixel-service';
import {RegistrationPathService} from './registration-path-service';
import {RotationsService} from './rotations-service';
import {layoutSdo} from './sdo-layout';
import {userInfoSdo} from './sdo-user-info';
import {UserInfoService} from './user-info-service';
import {modalsConfig} from './__config/modals';
import {outboundRedirects} from './__config/outbound-redirects';
import {redirectUrls} from './__config/redirect-urls.js';
import Cookies from 'js-cookie';

// Adding global modals
ModalService.addModals(modalsConfig);

// Defaulting to US + CA
if (!Cookies.get(cookies.WDIO_COUNTRY_CODE) && (!Cookies.get(cookies.COUNTRY_CODE) || !Cookies.get(cookies.REGION))) {
  Cookies.set(cookies.COUNTRY_CODE, 'US', cookies.CONFIG);
  Cookies.set(cookies.REGION, 'CA', cookies.CONFIG);
}

// Overriding country code if wdio test
if (Cookies.get(cookies.WDIO_COUNTRY_CODE)) {
  Cookies.set(cookies.COUNTRY_CODE, Cookies.get(cookies.WDIO_COUNTRY_CODE), cookies.CONFIG);
}
if (Cookies.get(cookies.WDIO_REGION_CODE)) {
  Cookies.set(cookies.REGION, Cookies.get(cookies.WDIO_REGION_CODE), cookies.CONFIG);
}

if (environment.DEV || environment.STAGE) {
  window.storeInstance = storeInstance;
}

/**
 * Initializing rotation service.
 */
const ready = RotationsService.getRoutes()
  .then((routes) => {
    // ------------------------------------------------------------------------>
    routerInstance.init(routes);
    // ------------------------------------------------------------------------>
    /**
     *
     * Updates store with server session values
     */
    routerInstance.beforeEach(async (request, response, next) => {
      try {
        const validateLoginResp = await ApiService.validateLogin();
        const isLoggedIn = Boolean(validateLoginResp?.payload?.token_valid); // eslint-disable-line

        UserInfoService.setUserInfo(validateLoginResp);
        const token = validateLoginResp?.payload?.token;
        userInfoSdo.isLoggedIn = isLoggedIn;
        userInfoSdo.token = token;
      } catch (e) {
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires the campaign attribution event if the URL contains the 'src_tag'
     * query string parameter.
     * Sets the 'cje' and 'cjd' cookies for the CJ from the
     * cjevent and cjdata query string parameters.
     */
    routerInstance.beforeEach((request, response, next) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const sourceTag = urlSearchParams.get(queryParamKeys.SOURCE_TAG);
      const cjEvent = urlSearchParams.get(queryParamKeys.CJ_EVENT);
      const cjData = urlSearchParams.get(queryParamKeys.CJ_DATA);

      if (cjEvent) {
        Cookies.set(cookies.CJ_E_COOKIE, cjEvent, cookies.CJ_CONFIG);
      }
      if (cjData) {
        Cookies.set(cookies.CJ_D_COOKIE, cjData, cookies.CJ_CONFIG);
      }

      if (sourceTag) {
        CampaignService.attribute(sourceTag);
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to the appropriate outbound url if the requested url is a valid outbound redirect url.
     */
    routerInstance.beforeEach((request, response, next) => {
      const url = response?.to?.toLowerCase().split('?')[0];
      const outboundUrl = outboundRedirects[url];
      if (outboundUrl) {
        window.location = outboundUrl;
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to /login if the user isnt logged in when requesting a protected route, also
     * redirects to download app if conditions are met.
     */
    routerInstance.beforeEach((request, response, next) => {
      const isLoggedIn = userInfoSdo.isLoggedIn;
      const isActive = userInfoSdo.userInfo.accountStatus !== 'inactive';
      const requestedPage = response.to.slice(-1) === '/' ? response.to : response.to + '/';

      const redirectConditions = {
        deactivatedAndRequestingSpecialOfferPageBlockedForMembers: {
          condition: () => {
            return isLoggedIn && !isActive && response?.matchedRoute?.meta?.blockedForMembers && requestedPage.includes(pages.SUBSCRIPTION_OFFER_SPECIAL_OFFER_15_FOR_12_BETA_USERS); // eslint-disable-line
          },
          resolve: () => {
            response.to = pages.SUBSCRIPTION_OFFER_SPECIAL_OFFER_15_FOR_12_BETA_USERS;
            response.popped = false;
          }
        },
        deactivatedAndRequestingPageBlockedForMembers: {
          condition: () => {
            return isLoggedIn && !isActive && response?.matchedRoute?.meta?.blockedForMembers;
          },
          resolve: () => {
            response.to = pages.REACTIVATION;
            response.popped = false;
          }
        },
        loggedInAndRequestingPageBlockedForMembers: {
          condition: () => {
            return isLoggedIn && isActive && response?.matchedRoute?.meta?.blockedForMembers;
          },
          resolve: async () => {
            await RegistrationPathService.next();
            response.popped = false;
          }
        },
        notLoggedInAndPageRequiresAuthentication: {
          condition: () => {
            return response?.matchedRoute?.meta?.requiresAuthentication && !isLoggedIn;
          },
          resolve: () => {
            const params = new URLSearchParams(window.location.search);
            response.to = pages.LOGIN + `?to=${encodeURIComponent(request.to)}`;
            for (const [key, value] of params.entries()) {
              if (key !== 'to') {
                response.to += `&${key}=${encodeURIComponent(value)}`;
              }
            }
            response.popped = false;
          }
        },
        inactiveRedirect: {
          condition: () => {
            const routeBlacklist =
            [...(response?.matchedRoute?.meta?.inactiveRedirect) ? [response?.matchedRoute?.path] : []];
            const blacklistedRoute = routeBlacklist.some((page) => {
              const cleanPagePath = PathUtils.removeTrailingSlash(page).split(':')[0];
              const cleanRequestPath = PathUtils.removeTrailingSlash(response.to);
              return cleanRequestPath.startsWith(cleanPagePath);
            });
            return isLoggedIn && !isActive && blacklistedRoute;
          },
          resolve: () => {
            response.to = pages.REACTIVATION;
            response.popped = false;
          }
        },
        default: {
          condition: () => true,
          resolve: () => {}
        }
      };

      const resolvedCondition = resolveCondition(redirectConditions);
      resolvedCondition.resolve();

      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Calls Subscription/UpgradeEligibility and redirects to appropriate page if logged in user is
     * not eligible and requesting the standalone upgrade
     */
    routerInstance.after(async (request, response, next) => {
      const isLoggedIn = userInfoSdo.isLoggedIn;
      const isInactive = userInfoSdo.userInfo.accountStatus === 'inactive';
      const requestPath = response?.matchedRoute?.path + '/';

      if (isLoggedIn && requestPath === pages.UPGRADE_STANDALONE && !environment.IN_APP) {
        try {
          const isUpgradeEligible = await ApiService.getUpgradeEligibility();

          if (!isUpgradeEligible?.payload?.is_upgrade_eligible) { // eslint-disable-line
            if (isInactive) {
              routerInstance.navigate(pages.REACTIVATION, {forceReload: true});
            } else {
              RegistrationPathService.next();
            }
          }
        } catch (e) {
          RegistrationPathService.next();
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects the user to the coming-soon page if the requested route requires a redeem code and the code is invalid.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response?.matchedRoute?.meta?.requiresValidRedeemCode) {
        try {
          const urlSearchParam = new URLSearchParams(location.search);
          const redeemCode = urlSearchParam.get(queryParamKeys.CODE);
          const validateCodeResp = await ApiService.validateAccessCode(redeemCode);
          if (validateCodeResp?.payload?.is_valid === false) {
            throw new Error('Code is invalid.');
          }
        } catch (err) {
          response.to = pages.HOME;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Call LogEmailClick if email click query param
     */
    routerInstance.beforeEach(async (request, response, next) => {
      const urlSearchParam = new URLSearchParams(location.search);
      const emailClickParam = urlSearchParam.get(queryParamKeys.EMAIL_CLICK);

      if (emailClickParam) {
        try {
          const payload = {
            requestUrl: location.href
          };
          await ApiService.logEmailClick(payload);
        } catch (err) {
          response.to = pages.HOME;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Remove linkFromApp cookie if linkFromApp query param is not present
     */
    routerInstance.beforeEach((request, response, next) => {
      const urlSearchParam = new URLSearchParams(location.search);
      const linkFromAppParam = urlSearchParam.get(queryParamKeys.LINK_FROM_APP);

      if (!linkFromAppParam) {
        Cookies.remove(cookies.LINK_FROM_APP);
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires global pixel on each page landing
     */
    routerInstance.after((request, response, next) => {
      const pageSpecificPixels = response?.matchedRoute?.meta?.pixels;
      if (pageSpecificPixels && pageSpecificPixels.length > 0) {
        pageSpecificPixels.forEach(async (pixel) => {
          const {pixelEvent, replacementTokens} = pixel;
          if (pixelEvent && pixelEvents[pixelEvent]) {
            await PixelService.fire(pixelEvents[pixelEvent], replacementTokens);
          }
        });
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Call logout to destroy the server session, clear the browser's
     * cookies, localStorage, sessionStorage, wipe session sdo, and
     * force refresh user to home.
     *
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response.to.startsWith(pages.CLEAR)) {
        const isLoggedIn = userInfoSdo.isLoggedIn;

        if (isLoggedIn) {
          await ApiService.userLogout(userInfoSdo.userInfo.userToProductUuid);
        }

        Object.keys(Cookies.get()).forEach((cookieName) => {
          const wdioCookieWhitelist = [
            cookies.WDIO,
            cookies.WDIO_SESSION_ID,
            cookies.WDIO_SUPPRESS_PIXELS,
            cookies.WDIO_TEST_EMAIL,
            cookies.WDIO_COUNTRY_CODE,
            cookies.WDIO_REGION_CODE,
            cookies.COUNTRY_CODE,
            cookies.WDIO_BLOCK_GDPR_BANNER
          ];
          if (wdioCookieWhitelist.includes(cookieName)) return;
          Cookies.remove(cookieName, cookies.CONFIG);
        });

        localStorage.removeItem(localStorageKeys.AOFL_TOKEN);
        localStorage.removeItem(localStorageKeys.APP_TOKEN);
        localStorage.removeItem(localStorageKeys.EMAIL);
        localStorage.removeItem(localStorageKeys.REDEEM_CODE);
        sessionStorage.clear();

        if (!Cookies.get(cookies.WDIO)) {
          window.location = '/';
          return;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects if path matches originating source.
     */
    routerInstance.beforeEach((request, response, next) => {
      const url = response?.to?.toLowerCase();
      if (typeof redirectUrls[url] !== 'undefined' && redirectUrls[url]) {
        window.location = redirectUrls[url];
        return;
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     */
    routerInstance.after((request, response, next) => {
      // Overriding country code if wdio test
      if (Cookies.get(cookies.WDIO_COUNTRY_CODE)) {
        Cookies.set(cookies.COUNTRY_CODE, Cookies.get(cookies.WDIO_COUNTRY_CODE), cookies.CONFIG);
      }
      if (Cookies.get(cookies.WDIO_REGION_CODE)) {
        Cookies.set(cookies.REGION, Cookies.get(cookies.WDIO_REGION_CODE), cookies.CONFIG);
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires page load events
     */
    routerInstance.after((request, response, next) => {
      const eventPayload = response?.matchedRoute?.meta?.pageInfo[0];
      const urlParamObj = {};
      const urlSearchParams = new URLSearchParams(window.location.search);

      const urlParamsArray = urlSearchParams.toString().split('&');
      urlParamsArray.forEach((param) => {
        const [key, val] = param.split('=');
        urlParamObj[key] = val;
      });

      const hasUserToProductUuid = userInfoSdo?.userInfo?.userToProductUuid !== '';

      const pageEvent = {
        event_type: eventNames.PAGE_LOAD,
        event_payload: {
          aofl_product: 'home_school',
          page_info: {
            site_section: 'regpath',
            page_name: eventPayload?.page_info_key,
            page_url: window.location.origin + response?.matchedRoute?.path,
            view_info: []
          },
          url_variable_info: urlParamObj,
          referrer: request.from.replace(/^\//, location.origin + '/'),
          ...(hasUserToProductUuid && {user_to_product_uuid: userInfoSdo?.userInfo?.userToProductUuid})
        }
      };
      marketingTrackerQueue.enqueueEvent(pageEvent);
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires a rotation assignment event, if needed.
     */
    routerInstance.after((request, response, next) => {
      const currentRotationVersion = response?.matchedRoute?.rotationInfo?.version;
      const currentPage = response?.matchedRoute?.path === '/' ? 'home' : response?.matchedRoute?.path.slice(1);

      RotationsService.fireRotationAssignment(currentRotationVersion, currentPage);

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Close all modals and dropdown menus
     */
    routerInstance.after((request, response, next) => {
      if (typeof layoutSdo !== 'undefined') {
        ModalService.hide();
        layoutSdo.reset();
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    routerInstance.navigate(location.href.replace(location.origin, '').replace('index.html', ''), {forceReload: true});
    // ------------------------------------------------------------------------>
  });

export {
  ready,
  routerInstance
};
