import '@aofl/picture';
import '../link-to-element';
import {cookies, pages} from '../constants-enumerate';
import {imageConfig} from './__config/images';
import Cookies from 'js-cookie';

export default (ctx, html) => html`
  <nav id="main-header-content">
    <div id="skip-navigation">
      <slot name="skip-navigation"></slot>
    </div>
    <div id="left-container" class="side-container">
      <slot name="left"></slot>
      ${ctx.preventRedirectAlt || Cookies.get(cookies.LINK_FROM_APP) ? html`
        <div id="text-logo">
          <picture>
            ${imageConfig.homeschoolPlusTextLogo.map((image) => html`
              <source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
            `)}
            <img class="enteractive tab-outline-blue" src='${imageConfig.homeschoolPlusTextLogo[5].src}' width="399" height="31" alt="Text logo for Homeschool Plus">
          </picture>
        </div>
      ` : html`
        <link-to dom-scope="ACRx6UEW" id="text-logo" href="${pages.HOME}" tab-outline-blue>
          <picture>
            ${imageConfig.homeschoolPlusTextLogo.map((image) => html`
              <source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
            `)}
            <img class="enteractive tab-outline-blue" src='${imageConfig.homeschoolPlusTextLogo[5].src}' width="399" height="31" alt="Text logo for Homeschool Plus">
          </picture>
        </link-to>
      `}
    </div>
    ${ctx.preventRedirect || Cookies.get(cookies.LINK_FROM_APP) ? html`
      <div>
        <picture>
          ${imageConfig.homeschoolPlusLogo.map((image) => html`
            <source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
          `)}
          <img id="logo" class="enteractive tab-outline-blue" src='${imageConfig.homeschoolPlusLogo[5].src}' width="522" height="88" alt="Logo for Homeschool Plus">
        </picture>
      </div>
    ` : html`
      <link-to dom-scope="51b2L86V" id="logo" href="${pages.HOME}" tab-outline-blue>
        <picture>
          ${imageConfig.homeschoolPlusLogo.map((image) => html`
            <source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
          `)}
          <img class="enteractive tab-outline-blue" src='${imageConfig.homeschoolPlusLogo[5].src}' width="522" height="88" alt="Logo for Homeschool Plus">
        </picture>
      </link-to>
    `}
    <div id="right-container" class="side-container">
      <slot name="right"></slot>
    </div>
  </nav>
`;
